import "../../App.css";
import React, { Component } from "react";
import { connect } from "react-redux";
import { LOADER, LOGEDUSER } from "../../redux/actions";
import moment from "moment";
import { Redirect } from "react-router-dom";
import Loader from "../loader";
import { withRouter } from 'react-router-dom';

function mapStateToProps(state) {
  return {
    data: state.reducer,
    user: state.userReducer,
    Devise: state.DeviseReducer,
  };
}

const mapDispatchToProps = {
  LOADER,
  LOGEDUSER,
};

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);



class clic2payConfirm extends Component {
  constructor(props) {
    super();
    this.state = {
      params: window.location.search
        .slice(1)
        .split("&")
        .reduce((acc, s) => {
          const [k, v] = s.split("=");
          return Object.assign(acc, { [k]: v });
        }, {}),
      redirect: false,
    };
  }
  
  async componentDidMount() {
    
    fetch(
      `https://test.clictopay.com/payment/rest/getOrderStatusExtended.do?userName=0870526019&password=YqT9Ks6p8&orderId=${urlParams.get('orderId')}&language=en`,
      {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      }
    )
      .then(function (response) {
        if (!response.ok) {
          throw new Error("HTTP status " + response.status);
        }
        return response.json();
      })
      .then(
        (result) => {
           if (result.errorMessage=="Success"){
             this.booking();

           } else {

           }
        },
        (error) => {}
      );
  }

  booking = () => {
    var self=this;
    this.props.LOADER(true);
    fetch(
      `${process.env.REACT_APP_API_KEY_PROD}api/hotelbooking/clic2pay/${this.props.code}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          arriving: moment(this.props.data.arriving).format("YYYY/MM/DD"),
          departing: moment(this.props.data.departing).format("YYYY/MM/DD"),
          rooms: this.props.data.roomsbooked,
          devise: this.props.Devise.devise,
          firstlastname: this.props.user.firstlastname,
          email: this.props.user.email,
          telephone: this.props.user.phone,
          nationality: this.props.user.country,
          address: this.props.user.address,
          finalprice: this.props.data.totalbooking,
          clic2paysessionkey: urlParams.get('orderId'),
          nights: this.props.data.nights,
          specialrequest: this.props.data.specialrequest,
          pax: this.props.data.pax,
        }),
      }
    )
      .then(function (response) {
        if (!response.ok) {
          throw new Error("HTTP status " + response.status);
        }
        return response.json();
      })
      .then(
        (result) => {
            this.props.history.push(`/backoffice/?hotelid=${this.props.code}`);
        },
        (error) => {
            this.props.history.push(`/?hotelid=${this.props.code}`);

        }
      );
  };

  render() {
 
    return <Loader></Loader>;
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(clic2payConfirm));
