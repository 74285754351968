import "../../App.css";
import React, { Component } from "react";
import "../../flatpicker.css";
import { connect } from 'react-redux';
import { LOADER, ROOMSBOOKED, TOTALBOOKING } from '../../redux/actions';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import moment from 'moment';
import Modalrooms from './modal/modalrooms';
import { Redirect } from "react-router-dom";
import { Spinner, Button } from 'react-bootstrap';
import ModalLogin from '../Components/modallogin/login_popup';

function mapStateToProps(state) {
  return {
    data: state.reducer,
    user:state.userReducer,
    Devise:state.DeviseReducer,
  }
}

const mapDispatchToProps = {
  TOTALBOOKING,
  ROOMSBOOKED,
  LOADER,
};

class Rooms extends Component {

  constructor() {
    super();
    this.picker2 = React.createRef();
    this.reserve = React.createRef();
    this.state = {
      date: new Date(),
      loading: true,
      rooms: [],
      photoIndex: 0,
      isOpen: false,
      images: [],
      collapseID: "",
      selectedroom: 1,
      showrates: "SHOW RATES",
      roomRateID: "",
      bookedInfo: [],
      clickedbutton: "",
      roombooking: [],
      redirectoreservation: false,
      loadingroomselection: false,
      total: 0,
      stopsale: "",
      bookingwindowmsg: [],
      nightspromotion: [],
      buttondisabled: false,
      roomss: [],
      modal1:false,
    };
  }

   toggle = nr => () => {
    this.setState({
      modal1:!this.state.modal1
    })
  }

  static defaultProps = {
    data: { rooms: 1 }
  };

  async componentDidMount() {
    var rooms = [];
    for (var j = 0; j < this.props.data.rooms; j++) {
      rooms[j] = false;
    }
    for (var j = 0; j < this.props.data.rooms; j++) {
      if (typeof this.props.data.roomsbooked[j] === 'undefined') {
      } else {
        rooms[this.props.data.roomsbooked[j].selectedroom] = true;
      }
    }
    this.setState({
      roomss: rooms,
    })
    await Promise.all([
      this.fetchData()
    ]);
    var ln = this.props.data.roomsbooked.length;
    var data = this.props.data.roomsbooked;
    if (ln > 0) {
      data.map((b, i) => {
        return (
          <div>
            {this.roomSelection(i, b.id + "-" + b.boards[0].id, b.boards[0].apiprice, true, ln, i)}
          </div>
        )
      })
    }
  }

  /* HOTEL AVAILIBILTY API REQUEST !!! */

  fetchData = (i) => {
    var token = localStorage.getItem('token');
    var index = i ? i : 0;
    this.props.LOADER(true);
    fetch(`${process.env.REACT_APP_API_KEY_PROD}api/HotelAvailibility/${this.props.code}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ "arriving": moment(this.props.data.arriving).format('YYYY/MM/DD'), "departing": moment(this.props.data.departing).format('YYYY/MM/DD'), "guests": this.props.data.guests[index], "nights": this.props.data.nights,"token":token })
    })
      .then(function (response) {
        if (!response.ok) {
          throw new Error("HTTP status " + response.status);
        }
        return response.json();
      })
      .then(
        (result) => {
          this.setState({
            rooms: typeof result.rooms === 'undefined' ? [] : result.rooms,
            selectedroom: index,
            stopsale: result.stopsales,
            stopsalerooms: result.roomsstopsales,
            bookingwindowmsg: result.bookingwindow,
            nightspromotion: result.nightspromotion,
            buttondisabled: false,
          })
          this.props.LOADER(false);
        },
        (error) => {


        }
      )
  }

  /* HOTEL AVAILIBILTY API REQUEST !!! */

  toggleCollapseElement = (element, collapseID) => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
  }

  executeScroll = () => this.reserve.current.scrollIntoView({ behavior: 'smooth' });

  OpenDatePicker = () => {
    this.picker2.current.flatpickr.clear();
    this.picker2.current.flatpickr.open();
  };

  openimages = (images) => {
    var data = [];
    {
      images.map((e, i) => (
        data.push(process.env.REACT_APP_API_KEY_PROD + e.path)
      ))
    }
    this.setState({ isOpen: true, images: data })
  }

  reserveroom = (i) => {
    var redirect = true;
    for (var j = 0; j < this.props.data.rooms; j++) {
      if (typeof this.props.data.roomsbooked[j] === 'undefined') {
        redirect = false;
        if (!this.state.roomss[0]) {
          this.fetchData(0);
        }
        else if (!this.state.roomss[1]) {
          this.fetchData(1);
        }
        else if (!this.state.roomss[2]) {
          this.fetchData(2);
        }
        return;
      }
    }
    if (redirect == true) {
      this.setState({
        redirectoreservation: true,
      })
    }
  }

  roomSelection = (i, roomRateID, price, checkapi, ln, curr) => {

    var token = localStorage.getItem('token');

    var index = i ? i : 0;

    var roomid = roomRateID.split('-')[0];

    var boardid = roomRateID.split('-')[1];

    this.setState({
      clickedbutton: roomid + "-" + boardid,
      loadingroomselection: true,
    })

    fetch(`${process.env.REACT_APP_API_KEY_PROD}api/checkRate/${this.props.code}/${roomid}/${boardid}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ "arriving": moment(this.props.data.arriving).format('YYYY/MM/DD'), "departing": moment(this.props.data.departing).format('YYYY/MM/DD'), "guests": this.props.data.guests[index], "nights": this.props.data.nights,"token":token })
    })
      .then(function (response) {
        if (!response.ok) {
          throw new Error("HTTP status " + response.status);
        }
        return response.json();
      })
      .then(
        (result) => {
          if (typeof result[0] != 'undefined') {
            result[0]['selectedroom'] = this.state.selectedroom;
            if (checkapi) {
              var joined = this.state.roombooking;
              result[0]['guests'] = this.props.data.guests[index];
              joined = this.state.roombooking.concat(result[0]);
            } else {
              var joined = this.state.roombooking;
              result[0]['guests'] = this.props.data.guests[index];
              var rooms = [];
              for (var j = 0; j < this.props.data.rooms; j++) {
                rooms[j] = false;
              }
              if (this.state.roomss[this.state.selectedroom] === false) {
                joined = this.state.roombooking.concat(result[0]);
              } else {
                this.setState({
                  total: (parseFloat(this.state.total) - parseFloat(joined[this.state.selectedroom].boards[0].pricewithtax)).toFixed(3),
                })
                joined.splice(this.state.selectedroom, 1, result[0]);
              }
            }
            if (price == result[0].boards[0].apiprice) {
              this.setState({
                bookedInfo: roomid + "-" + boardid + "-" + this.state.selectedroom,
                roombooking: joined,
                clickedbutton: "",
                total: (parseFloat(this.state.total) + parseFloat(result[0].boards[0].pricewithtax)).toFixed(3),
              })
              if (ln > 0) {
                if (curr + 1 == ln) {
                  this.setState({
                    loadingroomselection: false,
                  })
                }
              } else if (checkapi == false) {
                this.setState({
                  loadingroomselection: false,
                })
              }
              //this.executeScroll();
            }
            let markers = [...this.state.roomss];
            markers[this.state.selectedroom] = true;
            this.setState({
              buttondisabled: true,
              roomss: markers
            })
            this.props.TOTALBOOKING(this.state.total);
            this.props.ROOMSBOOKED(joined);
          }
        },
        (error) => {

        }
      )
  }

  insertMsgs = () => {
    var divre = "";
    var content = [];
    if (this.state.rooms.length>0){
    var promotions = this.state.rooms.map(item => item.promotion && item.promotion);
    if (promotions[0] || this.state.stopsale != "" || this.state.stopsalerooms || this.state.bookingwindowmsg.length > 0 || this.state.nightspromotion.length > 0) {
      divre = (<div className="row room ml-0 mr-0 mt-2 flex-column">
        <div className="promotion-description">
          <div className="d-flex align-items-center mb-2"><i className="far fa-bell"></i><h5 className="mb-0 ml-2">HOTEL PROMOTION</h5> </div>
          <ul className="notes-list">
            {this.state.bookingwindowmsg ? this.state.bookingwindowmsg.map((e, i) => (
              <li>{e}.</li>
            )) : ''}
            {this.state.nightspromotion ? this.state.nightspromotion.map((e, i) => (
              <li>{e}.</li>
            )) : ''}
            {promotions[0] ? promotions[0].map((e, i) => (
              <li>{e}.</li>
            )) : ''}
            {this.state.stopsale != "" ? <li>{this.state.stopsale}.</li> : ''}
            {this.state.stopsalerooms != "" ? <li>{this.state.stopsalerooms}.</li> : ''}
          </ul>
          {/* <li>Réduction 20% applicable sur tous les arrangements disponibles (LPD, DP, AI Soft et All Inclusive) .</li>
        <li>remise de 10%  (valable également sur les suppléments et les remises)  .</li> */}
        </div>
      </div>);
      }
  }
    content.push(divre);
    return content;
  }

  render() {
    if (!this.props.data.arriving) return (
      <Redirect to={`/?hotelid=${this.props.code}`} />
    )

    if (this.state.redirectoreservation) return (
      <Redirect to={`/booking?hotelid=${this.props.code}`} />
    )

    const { photoIndex, isOpen } = this.state;
    const { date } = this.state;
    const images = this.state.images;
    return (
      <div className="App rooms-page">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-4 col-lg-4 sidebar_wrapper">
              <div className={this.state.loadingroomselection ? "sidebarcontaineropacity" : "sidebarcontainer"}   >
                {this.state.loadingroomselection && <Spinner animation="border" className="Spinnerroomselection" />}
                <aside className="sidebar">
                  <aside
                    id="hotelier-widget-booking-2"
                    className="widget widget--hotelier widget-booking"
                  >
                    <h5 className="widget-title" >My Stay</h5>
                    <div className="d-flex widget-stay justify-content-between">
                      <div>
                        <strong>Check In</strong>
                        <p>After 12:00 AM</p>
                        <div className="d-flex">
                          <p>{moment(this.props.data.arriving).format('ddd')}</p>
                          <p className="ml-2">{moment(this.props.data.arriving).format('D')}/{moment(this.props.data.arriving).format('MM')}/{moment(this.props.data.arriving).format('YYYY')}</p>
                        </div>
                      </div>
                      <div className="ml-5">
                        <strong>Check Out</strong>
                        <p>After 12:00 PM</p>
                        <div className="d-flex">
                          <p>{moment(this.props.data.departing).format('ddd')}</p>
                          <p className="ml-2">{moment(this.props.data.departing).format('D')}/{moment(this.props.data.departing).format('MM')}/{moment(this.props.data.departing).format('YYYY')}</p>
                        </div>
                      </div>
                    </div>
                  </aside>
                  <aside
                    id="hotelier-widget-rooms-filter-2"
                    className="widget widget--hotelier widget-rooms-filter"
                  >
                    <div className="widget-rooms-filter__wrapper">
                      {this.state.roombooking.length >= 1 && <div className="yourreservation">
                        {this.state.roombooking.map((b, i) => (
                          <>
                            <div className="d-flex justify-content-between">
                              <p className="yourreservationdet reservation-room-nbr">Room {b.selectedroom + 1}</p>
                              <p className="yourreservationdet reservation-room-nbr priceslist">{ (b.boards[0].apiprice*this.props.Devise.exchange) + this.props.Devise.devise }</p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <u><p className="yourreservationdet">{b.nameRoom}</p></u>
                            </div>
                            <div className="d-flex align-items-center">
                              <i className="fas fa-mug-hot"></i>
                              <p className="yourreservationdet ml-2 reservation-room-nbr">{b.boards[0].description}</p>
                            </div>
                            <div className="d-flex justify-content-between taxandfees">
                              <p className="yourreservationdet">Taxes And Fees (7%)</p>
                              <p className="yourreservationdet reservation-room-nbr fees">{b.boards[0].tax*this.props.Devise.exchange} {this.props.Devise.devise}</p>
                            </div>
                          </>
                        ))}
                        <div className="d-flex justify-content-between">
                          <p className="yourreservationdet reservation-room-total">Total</p>
                          <p className="yourreservationdet reservation-room-total reservation-room-nbr priceslist">{this.state.total*this.props.Devise.exchange} {this.props.Devise.devise}</p>
                        </div>
                      </div>}
                    </div>
                  </aside>
                </aside>
                {this.props.data.rooms > 1 && (
                  <aside className="sidebar mt-2">
                    <div className="align-items-center w-100 justify-content-center manage-rooms-selection">
                      <div className="d-flex align-items-center"><i className="fas fa-pen-square"></i> <h5 className="title-edit ml-2" >You are editing your reservation.</h5></div>
                      <p className="title-edit-p">Please select a different room and rate below, or update your stay details above. Price and availability may change.</p>
                      <Modalrooms total={this.state.total} rooms={this.props.data.rooms} checkin={this.props.data.arriving} checkout={this.props.data.departing} guests={this.props.data.guests} selectedroom={this.state.selectedroom} fetchData={this.fetchData} />
                    </div>
                  </aside>
                )}
              </div>
            </div>
            <div className="col-12 col-md-8 col-lg-8  pt-0">
              <div className="container card-notes">
                <div className="row room ml-0 mr-0 flex-column">
                  <div className="d-flex align-items-center mb-2"><i className="fas fa-info-circle "></i><h5 className="mb-0 ml-2">IMPORTANT NOTE</h5> </div>
                  <ul className="notes-list  notes-list-hotel">
                    {this.props.issues.map((e, i) => (
                      <li>{e.description}</li>
                    ))}
                  </ul>
                </div>
                {this.insertMsgs()}
                <h5 className="widget-title pl-0 mt-4 mb-0">Select a Room</h5>
              </div>
              {this.state.rooms.length == 0 ? <div className="container card-rooms">
                <div className="row room ml-0 mr-0">
                  <p className="no-rooms-availibility"> We apologize for the inconvenience. There are no rooms available
                    from {this.props.data.arriving} to {this.props.data.departing} to accommodate the number of guests in your request.
                    Please consider booking multiple rooms </p>
                </div></div> : ""}
              {this.state.rooms.map((ro, i) => (
                <div className="container card-rooms" key={i}>
                  <div className="row room ml-0 mr-0">
                    <div className="col-12 col-md-3 col-lg-3">
                      <div className="d-flex flex-column ">
                        <div className="room-bg" style={{ backgroundImage: `url(${process.env.REACT_APP_API_KEY_PROD + ro.images[0]?.path})` }} width="100%" height="200px" onClick={() => this.openimages(ro.images)}></div>
                      </div>
                    </div>
                    <div className="col-12 col-md-7 col-7">
                      <div className=" d-flex flex-column ">
                        <h5 className="widget-title pl-0">{ro.nameRoom}</h5>
                      </div>
                      <div className="room__max-guests d-flex align-items-center">
                        <i className="fas fa-users"></i>
                        <div className="room__max-guests-label ml-2">up to {ro.maxPax} guests</div>{" "}
                      </div>
                      {this.props.segments && (
                        <div className="d-flex flex-wrap facilities facility-grp mt-2">
                          {this.props.segments.map((e, i) => (
                            <p className="pr-2 ">{e.description}</p>
                          ))}
                        </div>
                      )}
                      <div className="room__description room__description--listing">
                        <p>
                          {ro.description}
                        </p>
                      </div>
                      {ro.facilities && (
                        <div className="d-flex flex-wrap facilities mt-2">
                          {ro.facilities.map((e, i) => (
                            <div className="d-flex align-items-center facilities-icon"> <i className={e.icon}></i> <p className="pr-2 mb-0">{e.title}</p></div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="col-12 col-md-2 col-2 d-flex justify-content-center align-items-end flex-column">
                      <div className=" d-flex flex-column ">
                        <span className="room__price room__price--listing">
                          Price:<span className="amount priceslist">{ro.boards[0].apiprice*this.props.Devise.exchange} {this.props.Devise.devise}</span><br></br>
                          {ro.boards[0].apipricecalculated != ro.boards[0].apiprice ? <del><span className="amount priceslist">{ro.boards[0].apipricecalculated*this.props.Devise.exchange} {this.props.Devise.devise}</span></del> : ''}
                        </span>
                      </div>
                        <div className="btn-primary-container calendar availibility d-flex flex-column">
                          <button
                           
                            type="submit"
                            className=" style-1 button-stepper-showrates  showrates"
                            value=""
                            onClick={(e) => { this.toggleCollapseElement(e, 'basicCollapse' + i) }}
                          >
                            {this.state.collapseID == "basicCollapse" + i ? "HIDE RATES" : "SHOW RATES"}
                          </button>
                        </div>
                    </div>
                    {(ro.boards && ro.boards.length > 0) && (
                      <div className={this.state.collapseID == "basicCollapse" + i ? "collapse show w-100" : "collapse w-100"} >
                        {ro.boards.map((e, i) => (
                          <div className="room__rate room__rate--listing d-flex justify-content-between">
                            <div className="rate__content">
                              <div className="d-flex"><i className="fas fa-mug-hot"></i><h4 className="rate__room rate__room--listing ml-2">{e.description}</h4></div>
                              <div className="rate__non-cancellable-info rate__non-cancellable-info--listing">
                                <p>{e.Cancellationperiod == 0 ? 'Non Refundable' : 'Refundable'}</p>
                              </div>
                              <h4 className="rate__room rate__room--listing policy">{e.Cancellationperiod != 0 ? <span>Free cancellation up to {e.Cancellationperiod} {e.Cancellationperiod == 1 ? 'day' : 'days'}  before arrival </span> : <span>In case of cancellation you will be charged the full amount.</span>}</h4>
                              <ul className="notes-list">
                                {e.Cancellationperiod != 0 ? <li>cancel up to {e.Cancellationperiod} {e.Cancellationperiod == 1 ? 'day' : 'days'} prior to your arrival.</li> : ''}
                                {e.promotion ? <li>{e.promotion}</li> : ''}
                              </ul>
                            </div>
                            <div className="rate__actions rate__actions--listing">
                              <div className="rate__price rate__price--listing">
                                <span className="rate__price rate__price--listing">Price: <span className="amount priceslist">{e.apiprice*this.props.Devise.exchange} {this.props.Devise.devise}</span></span>{e.apipricecalculated != e.apiprice ? <del><span className="amount priceslist">{e.apipricecalculated*this.props.Devise.exchange} {this.props.Devise.devise}</span></del> : ''}<p className="mb-0">Excluding Taxes  & Fees
                                </p>
                                <span className="room__price-description"></span>
                              </div>
                              <div className="add-to-cart-wrapper add-to-cart-wrapper--rate">
                                {this.state.clickedbutton == ro.id + "-" + e.id ?
                                  <Button variant="primary" disabled className="style-1 button-stepper-availibity showrates">
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  </Button> :
                                  <button
                                    type="submit"
                                    className="style-1 button-stepper-availibity showrates"
                                    value=""
                                    onClick={this.props.user.loged ? (event) => this.roomSelection(this.state.selectedroom, ro.id + "-" + e.id + "-" + e.code, e.apiprice, false, 0, 0) : this.toggle(1)}
                                  >
                                    {this.state.bookedInfo == ro.id + "-" + e.id + "-" + this.state.selectedroom ? <span className="add-to-cart-selected"><span className="add-to-cart-selected__count">1</span>
                                      <span className="add-to-cart-selected__text">room selected</span>
                                    </span> : 'BOOK NOW'}
                                  </button>
                                }
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ))}
                <ModalLogin ModalLogin={this.state.modal1} toggle={this.toggle}/>
              <div className="container card-rooms">
                <div className="row justify-content-end ml-0 mr-0">
                  <div className="btn-primary-container calendar availibility d-flex justify-content-end  ml-4">
                    <button
                      type="submit"
                      className={this.state.buttondisabled ? "style-1 button-stepper-Reserve" : " style-1 button-stepper-Reserve disabled-reserve-btn"}
                      disabled={this.state.buttondisabled ? "" : "true"}
                      value=""
                      id="reserve-button"
                      ref={this.reserve}
                      onClick={() => this.reserveroom(this.state.selectedroom)}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(Rooms)