import "../../../App.css";
import React, { Component } from "react";
import "../../../flatpicker.css";
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import { MDBBtn, MDBCollapse } from "mdbreact";
import ReactFlagsSelect from 'react-flags-select';
import SimpleReactValidator from 'simple-react-validator';
import { Redirect } from "react-router-dom";
import { Spinner, Button } from 'react-bootstrap';
import IntPhone from '../../Components/signup_signin_pages/intphone'
import jwt_decode from "jwt-decode";
import { isValidPhoneNumber } from 'react-phone-number-input'
import moment from 'moment';
import Flatpickr from "react-flatpickr";
import { LOADER,DEVISE } from '../../../redux/actions';


function mapStateToProps(state) {
    return {
        data: state.userReducer,
        user: state.userReducer,
        devise: state.DeviseReducer,
    }
}

const mapDispatchToProps = {
    LOADER,
};

class EditUser extends Component {

    constructor(props) {

        super();
        var currentDate = new Date();
        this.validator = new SimpleReactValidator({
            messages: {
                firstname: 'Some message',
            },
        });

        this.state = {
            date: "",
            collapseID: "",
            selectedCountry: props.data.country,
            name: props.data.firstlastname,
            username: props.data.username,
            email: props.data.email,
            address: props.data.address,
            phone: props.data.phone,
            phonemsgerror: '',
            city: props.data.city,
            emailerror: '',
            company: props.data.company,
            message: 'Reset Password',
            credithistory: [],
            remaining: "",
            used: "",
            start: "",
            end: "",
        }
    }

    toggleCollapse = collapseID => () => {
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.user.roles.indexOf("ROLE_PARTICULAR") == -1) {
            this.props.LOADER(true);
            fetch(`${process.env.REACT_APP_API_KEY_PROD}api/CreditHistory`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "token": localStorage.getItem("token")
                })
            })
                .then(function (response) {
                    if (!response.ok) {
                        throw new Error("HTTP status " + response.status);
                    }
                    return response.json();
                })
                .then(
                    (result) => {
                        this.props.LOADER(false);
                        this.setState({
                            credithistory: result.data,
                            used: result.used,
                        })
                    },
                    (error) => {
                        this.props.LOADER(false);
                    }
                )
        }
    }

    phoneinputFn = (value) => {
        this.setState({
            phone: value
        })
    }

    submitForm = (e) => {
        e.preventDefault();
        var self = this;
        if (this.validator.allValid() && isValidPhoneNumber(this.state.phone)) {
            this.setState({
                clickedbutton: true,
            })
            fetch(`${process.env.REACT_APP_API_KEY_PROD }api/UpdateProfile/${localStorage.getItem('token')}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "username": this.state.username,
                    "email": this.state.email,
                    "name": this.state.name,
                    "country": this.state.selectedCountry,
                    "city": this.state.city,
                    "address": this.state.address,
                    "phone": this.state.phone,
                })
            })
                .then(function (response) {
                    if (!response.ok) {
                        self.setState({ emailerror: 'Error', clickedbutton: false })
                        throw new Error("HTTP status " + response.status);
                    }
                    return response.json();
                })
                .then(
                    (result) => {
                        self.setState({ emailerror: result.msg, clickedbutton: false });
                        localStorage.removeItem("token");
                        localStorage.setItem("token", result.token);
                    },
                    (error) => {
                        self.setState({ emailerror: 'Error', clickedbutton: false })
                    }
                )
        } else {
            if (this.state.phonemsgerror == "") {
                this.state.phonemsgerror = "Phone number required";
            }
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    ResetPassword = (e) => {
        var self = this;
        fetch(`${process.env.REACT_APP_API_KEY_PROD}api/ForgetPassword/${this.state.email}/${this.props.code}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(function (response) {
                if (!response.ok) {
                    self.setState({ errorlogin: 'Email Not Found' })
                    throw new Error("HTTP status " + response.status);
                }
                return response.json();
            })
            .then(
                (result) => {
                    self.setState({
                        message: 'Email has been sent , Please check your mail box',
                    })
                },
                (error) => {
                    self.setState({ message: 'Email Not Found' })
                }
            )
    }

    filterCreditHistory = () => {
        fetch(`${process.env.REACT_APP_API_KEY_PROD}api/CreditHistoryByDate`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "token": localStorage.getItem("token"), "start": this.state.start, "end": this.state.end
            })
        })
            .then(function (response) {
                if (!response.ok) {
                    throw new Error("HTTP status " + response.status);
                }
                return response.json();
            })
            .then(
                (result) => {
                    this.setState({
                        credithistory: result.data,
                    })
                },
                (error) => {
                }
            )
    }

    onChangeDates = (selectedDates, dateStr, instance) => {
        if (dateStr && dateStr.split(" to ")[1] != undefined) {
            this.setState({
                start: moment(dateStr.split(" to ")[0]).format('L'),
                end: moment(dateStr.split(" to ")[1]).format('L'),
            });
        } else {
            this.setState({
                start: moment(dateStr.split(" to ")[0]).format('L'),
                end: moment(dateStr.split(" to ")[0]).format('L'),
            });
        }
    }

    render() {

        

        const customStyles = {
            headCells: {
                style: {
                    background: '#0f2453',
                    color: 'white',
                    fontWeight: '500',
                },
            },
            rows: {
                style: {
                    fontWeight: 'bold',
                    background: '#fafdfd',
                },
            },
        };
        const columns = [
            {
                name: 'FirstLastname',
                selector: row => row.FirstLastname,
            },
            {
                name: 'Username',
                selector: row => row.Username,
            },
            {
                name: 'Email Address',
                selector: row => row.EmailAddress,
            },
            {
                name: 'City',
                selector: row => row.City,
            },
            {
                name: 'Phone',
                selector: row => row.Phone,
            },
            {
                name: 'Actions',
                selector: row => row.Actions,
            },
        ];

        const data = [
            {
                FirstLastname: this.state.name,
                Username: this.state.username,
                EmailAddress: this.state.email,
                City: this.state.city,
                Phone: this.state.phone,
                Actions: <button className="btn-action" onClick={this.toggleCollapse("basicCollapse")}>Edit</button>,
            },

        ]

        const columnsA = [
            {
                name: 'FirstLastname',
                selector: row => row.FirstLastname,
            },
            {
                name: 'Username',
                selector: row => row.Username,
            },
            {
                name: 'Company',
                selector: row => row.Company,
            },
            {
                name: 'Email Address',
                selector: row => row.EmailAddress,
            },
            {
                name: 'City',
                selector: row => row.City,
            },
            {
                name: 'Phone',
                selector: row => row.Phone,
            },
            {
                name: 'Actions',
                selector: row => row.Actions,
            },
        ];

        const dataA = [
            {
                Company: this.state.company,
                FirstLastname: this.state.name,
                Username: this.state.username,
                EmailAddress: this.state.email,
                City: this.state.city,
                Phone: this.state.phone,
                Actions: <button className="btn-action" onClick={this.toggleCollapse("basicCollapseA")}>Edit</button>,
            },
        ]

        if (this.props.user.roles.indexOf("ROLE_PARTICULAR") == -1) {
            var columnsCredit = [
                {
                    name: 'Deposit Date',
                    selector: row => row.Deposit,
                },
                {
                    name: 'Added By',
                    selector: row => row.AddedBy,
                },
                {
                    name: 'Amount',
                    selector: row => row.Amount,
                },
                {
                    name: 'Label',
                    selector: row => row.Label,
                },
            ];
            var dataCredit = [];
            this.state.credithistory.map((e => {
                let credit = {
                    Deposit: moment(e.createdAt).format('MMMM Do YYYY, h:mm:ss a'),
                    AddedBy: e.addedby,
                    Amount: e.budget+" "+this.props.devise.devise,
                    Label: e.notes,
                }
                dataCredit.push(credit);
            }));
        }

        return (
            <div className="Backoffice">
                <div className="container">
                    <div className="d-flex align-items-center mb-4 mt-2"> <i className="far fa-list-alt"></i> <h1 className="mb-0 ml-2 cursor-pointer">Manage User Account</h1></div>
                    {this.props.data.roles.indexOf("ROLE_PARTICULAR") !== -1 ?
                        <>
                            <DataTable
                                columns={columns}
                                data={data}
                                customStyles={customStyles}
                            />
                            <MDBCollapse id="basicCollapse" isOpen={this.state.collapseID}>
                                <div className="form form--login w-100 p-3 form-edit-user">

                                    <form action="#" method="post" className="js-form-gms-login">
                                        <p className="resetpasswordbtn" onClick={this.state.message == "Reset Password" ? this.ResetPassword : ''}>{this.state.message}</p>
                                        <div className="form__body">
                                            <div className="d-flex justify-content-between">
                                                <div className="form__row w-50 mr-3">
                                                    <label htmlFor="field-login-email" className="form__label">
                                                        First-Last name
                                                    </label>
                                                    <div className="form__controls">
                                                        <input
                                                            type="text"
                                                            className="field"
                                                            placeholder=""
                                                            required=""
                                                            value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })}
                                                        ></input>
                                                        {this.validator.message('First-Last name', this.state.name, 'required')}
                                                    </div>
                                                </div>
                                                <div className="form__row w-50">
                                                    <label className="form__label">
                                                        Username
                                                    </label>

                                                    <div className="form__controls">
                                                        <input
                                                            type="text"
                                                            className="field"
                                                            placeholder=""
                                                            required=""
                                                            readOnly
                                                            value={this.state.username}
                                                        ></input>
                                                        {this.validator.message('username', this.state.username, 'required')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div className="form__row w-100 mr-3">
                                                    <label htmlFor="field-login-email" className="form__label">
                                                        Email Address *
                                                    </label>
                                                    <div className="form__controls">
                                                        <input
                                                            type="email"
                                                            className="field"
                                                            placeholder=""
                                                            required=""
                                                            readOnly
                                                            value={this.state.email}
                                                        ></input>
                                                        {this.validator.message('email', this.state.email, 'required|email')}
                                                        <div className="app-field-message"><span>* Booking confirmations will be sent to this address.</span></div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div className="form__row w-50 mr-3">
                                                    <label htmlFor="field-login-email" className="form__label">
                                                        Country *
                                                    </label>
                                                    <div className="form__controls">
                                                        <ReactFlagsSelect
                                                            selected={this.state.selectedCountry}
                                                            onSelect={code => this.setState({ selectedCountry: code })}
                                                            searchable
                                                            selectButtonClassName="country-input"
                                                            className="menu-flags"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form__row w-50">
                                                    <label htmlFor="field-login-email" className="form__label">
                                                        City
                                                    </label>

                                                    <div className="form__controls pt-3">
                                                        <input
                                                            type="text"
                                                            className="field"
                                                            placeholder=""
                                                            required=""
                                                            value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })}
                                                        ></input>
                                                        {this.validator.message('city', this.state.city, 'required')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div className="form__row w-100 ">
                                                    <label htmlFor="field-login-email" className="form__label">
                                                        Address *
                                                    </label>
                                                    <div className="form__controls">
                                                        <input
                                                            type="text"
                                                            className="field"
                                                            placeholder=""
                                                            required=""
                                                            value={this.state.address} onChange={(e) => this.setState({ address: e.target.value })}
                                                        ></input>
                                                        {this.validator.message('address', this.state.address, 'required')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div className="form__row w-100">
                                                    <label htmlFor="field-login-email" className="form__label">
                                                        Phone *
                                                    </label>
                                                    <div className="form__controls">
                                                        <IntPhone phoneFn={this.phoneinputFn} phone={this.state.phone} phonemsgerror={this.state.phonemsgerror} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form__actions">
                                            {this.state.clickedbutton ?
                                                <Button disabled className="form__btn ">
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                </Button> :
                                                <button
                                                    type="submit"
                                                    className="form__btn "
                                                    value=""
                                                    onClick={(e) => this.submitForm(e)}
                                                >
                                                    Save
                                                </button>
                                            }
                                        </div>
                                        <div className="srv-validation-message text-center">{this.state.emailerror}</div>
                                    </form>
                                </div>
                            </MDBCollapse>
                        </>
                        :
                        <>
                            <div className="row mb-3">
                                <div className="col-lg-6">
                                    <div className="reservation-wrapper">
                                        <div className="card-bookingreservation cardreservation card-credit">
                                            <label className="mt-0">Your Credit</label>
                                            <h1 className="Credit">{this.props.user.deposit} {this.props.devise.devise}</h1>
                                            <div className="d-flex justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <label>Used :</label>
                                                    <h1 className="Credit mb-0 ml-1 mt-2">{this.state.used} {this.props.devise.devise}</h1>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <label>Remaining :</label>
                                                    <h1 className="Credit mb-0  ml-1 mt-2">{this.props.user.deposit} {this.props.devise.devise}</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-6">
                                    <div className="reservation-wrapper">
                                        <div className="card-bookingreservation cardreservation justify-content-between align-items-center card-credit">
                                            <label>Your Credit History</label><br></br>
                                            <div>
                                                <Flatpickr
                                                    className="flat-pickrcredit"
                                                    options={{ mode: "range" }}
                                                    value={this.state.date}
                                                    onChange={this.onChangeDates}
                                                />
                                                <button className="btn" onClick={this.filterCreditHistory}>View History</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <DataTable
                                columns={columnsA}
                                data={dataA}
                                customStyles={customStyles}
                            />
                            <MDBCollapse id="basicCollapseA" isOpen={this.state.collapseID}>
                                <div className="form form--login w-100 p-3 form-edit-user">
                                    <form action="#" method="post" className="js-form-gms-login">
                                        <p className="resetpasswordbtn" onClick={this.state.message == "Reset Password" ? this.ResetPassword : ''}>{this.state.message}</p>

                                        <div className="form__body">
                                            <div className="d-flex justify-content-between">
                                                <div className="form__row w-100 ">
                                                    <label htmlFor="field-login-email" className="form__label">
                                                        Company
                                                    </label>
                                                    <div className="form__controls">
                                                        <input
                                                            type="text"
                                                            className="field"
                                                            placeholder=""
                                                            required=""
                                                            readOnly
                                                            value={this.state.company} onChange={(e) => this.setState({ company: e.target.value })}
                                                        ></input>
                                                        {this.validator.message('company', this.state.address, 'required')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div className="form__row w-50 mr-3">
                                                    <label htmlFor="field-login-email" className="form__label">
                                                        First-Last name
                                                    </label>
                                                    <div className="form__controls">
                                                        <input
                                                            type="text"
                                                            className="field"
                                                            placeholder=""
                                                            required=""
                                                            value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })}
                                                        ></input>
                                                        {this.validator.message('First-Last name', this.state.name, 'required')}
                                                    </div>
                                                </div>
                                                <div className="form__row w-50">
                                                    <label className="form__label">
                                                        Username
                                                    </label>

                                                    <div className="form__controls">
                                                        <input
                                                            type="text"
                                                            className="field"
                                                            placeholder=""
                                                            required=""
                                                            value={this.state.username}
                                                        ></input>
                                                        {this.validator.message('username', this.state.username, 'required')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div className="form__row w-100 mr-3">
                                                    <label htmlFor="field-login-email" className="form__label">
                                                        Email Address *
                                                    </label>
                                                    <div className="form__controls">
                                                        <input
                                                            type="email"
                                                            className="field"
                                                            placeholder=""
                                                            required=""
                                                            value={this.state.email}
                                                        ></input>
                                                        {this.validator.message('email', this.state.email, 'required|email')}
                                                        <div className="app-field-message"><span>* Booking confirmations will be sent to this address.</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div className="form__row w-50 mr-3">
                                                    <label htmlFor="field-login-email" className="form__label">
                                                        Country *
                                                    </label>
                                                    <div className="form__controls">
                                                        <ReactFlagsSelect
                                                            selected={this.state.selectedCountry}
                                                            onSelect={code => this.setState({ selectedCountry: code })}
                                                            searchable
                                                            selectButtonClassName="country-input"
                                                            className="menu-flags"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form__row w-50">
                                                    <label htmlFor="field-login-email" className="form__label">
                                                        City
                                                    </label>

                                                    <div className="form__controls pt-3">
                                                        <input
                                                            type="text"
                                                            className="field"
                                                            placeholder=""
                                                            required=""
                                                            value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })}
                                                        ></input>
                                                        {this.validator.message('city', this.state.city, 'required')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div className="form__row w-100 ">
                                                    <label htmlFor="field-login-email" className="form__label">
                                                        Address *
                                                    </label>
                                                    <div className="form__controls">
                                                        <input
                                                            type="text"
                                                            className="field"
                                                            placeholder=""
                                                            required=""
                                                            value={this.state.address} onChange={(e) => this.setState({ address: e.target.value })}
                                                        ></input>
                                                        {this.validator.message('address', this.state.address, 'required')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div className="form__row w-100">
                                                    <label htmlFor="field-login-email" className="form__label">
                                                        Phone *
                                                    </label>
                                                    <div className="form__controls">
                                                        <IntPhone phoneFn={this.phoneinputFn} phone={this.state.phone} phonemsgerror={this.state.phonemsgerror} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form__actions">
                                            {this.state.clickedbutton ?
                                                <Button disabled className="form__btn ">
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                </Button> :
                                                <button
                                                    type="submit"
                                                    className="form__btn "
                                                    value=""
                                                    onClick={(e) => this.submitForm(e)}
                                                >
                                                    Save
                                                </button>
                                            }
                                        </div>
                                        <div className="srv-validation-message text-center">{this.state.emailerror}</div>
                                    </form>
                                </div>
                            </MDBCollapse>
                        </>
                    }
                    <br></br>
                    {this.props.data.roles.indexOf("ROLE_PARTICULAR") == -1 &&
                        <>
                            <div className="d-flex align-items-center mb-4 mt-2">
                                <i className="far fa-list-alt"></i> <h1 className="mb-0 ml-2 cursor-pointer">Billing History</h1></div>
                            <DataTable
                                columns={columnsCredit}
                                data={dataCredit}
                                customStyles={customStyles}
                                pagination
                            /> </> }
                </div>
            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditUser);